<template>
  <div></div>
</template>

<script>
//import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

import {mapGetters} from "vuex";

/**
 * Sample widgets data source
 */

export default {
  name: "dashboard",
  components: {},
  data() {
    return {
      tabIndex: 0,
      tabIndex2: 0
    };
  },
  mounted() {
    if(this.currentUser.role == 'ROLE_STUDENT'){
      this.$router.push({ name: "studentDashboard", params: {} });
    }else if(this.currentUser.role == 'ROLE_TEACHER'){
      this.$router.push({ name: "teacherDashboard", params: {} });
    }else if(this.currentUser.role == 'ROLE_SCHOOL_ADMIN'){
      this.$router.push({ name: "schoolAdminDashboard", params: {} });
    }else if(this.currentUser.role == 'ROLE_EDUCATION_DEPARTMENT' || this.currentUser.role == 'ROLE_MAIN_EDUCATION_DEPARTMENT'){
      this.$router.push({ name: "educationDepartmentSchools", params: {} });
    }else if(this.currentUser.role == 'ROLE_PRESCHOOL_ADMIN'){
      this.$router.push({ name: "PreschoolAdminDashboard", params: {} });
    }else if(this.currentUser.role == 'ROLE_PRESCHOOL_KINDERGARTENER'){
      this.$router.push({ name: "KindergartenerDashboard", params: {} });
    }else if(this.currentUser.role == 'ROLE_SCHOOL_NURSE'){
      this.$router.push({ name: "InformationAboutHeathPage", params: {} });
    }else if(this.currentUser.role == 'ROLE_SCHOOL_SITE_ADMIN'){
      this.$router.push({ name: "schoolPAPages", params: {} });
    }else{
      this.$router.push({ name: "schools", params: {} });
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser"
    ])
  }
};
</script>
